import React, { useState } from "react";
import { ProductList, SelectType } from "../pages/UserData";
import "./styles/ItemList.css";

export const ItemList: React.FC<any> = () => {
  const [viewtype, setviewtype] = useState(ProductList);
  
  const onOptionChangeHandler = (e: any) => {
    if (e.target.value === "View All") {
      setviewtype(ProductList);
      console.log("viewall", ProductList);
    } else {
      const filterSelect = ProductList.filter(
        (name) => name.Type === e.target.value
      );
      console.log("viewall1", filterSelect);
      setviewtype(filterSelect);
    }
  };

  return (
    <>
      <div className="item_body">
        <div className="fltline"></div>
        <div>
          <div className="type-grp">
            <form>
              <label className="type-select-lable">Type</label>
              <select
                className="type-select"
                name="Type"
                id="type1"
                onChange={onOptionChangeHandler}
              >
                {SelectType.map((item) => (
                  <option className="type-select-option" value={item.SelectList}>
                    {item.SelectList}
                  </option>
                ))}
              </select>
            </form>
          </div>
          <div className="item_grid">
            {viewtype.map((item) => (
              <div className="item_list">
                <div className="item_img">
                  <img className="item-logo" src={item.Image} alt="Product" />
                </div>
                <h3 className="item-name">{item.Name}</h3>
                <h4 className="item-code">Code: {item.Code}</h4>
                <h4 className="item-brand">Brand: {item.Brand}</h4>
                <h3 className="item-rate">{item.Rate}</h3>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
