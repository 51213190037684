import React from "react";
// import SimpleImageSlider from "react-simple-image-slider";
import { HeadBar } from "../components/HeadBar";
import "../styles/style.css";
import { SlideImage } from "../components/SlideImage";
import { ItemList } from "../components/ItemList";
import { FilterData } from "../components/FilterData";

interface HomePageProps {}

// const images = [
//   { url: "/slider_1.jpg" },
//   { url: "/slider_2.jpg" },
//   { url: "/slider_3.png" },
//   { url: "/slider_4.jpg" },
// ];

export const HomePage: React.FC<HomePageProps> = () => {

  return (
    <>
      <HeadBar />
      <SlideImage />
      {/* <SimpleImageSlider
        width={"100%"}
        height={504}
        images={images}
        showBullets={true}
        showNavs={true}
      /> */}
      <div className="hm-bdy">
      <FilterData />
      <ItemList />
      </div>
    </>
  );
};
