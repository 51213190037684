import "./App.css";
import { Approute } from "./routes/Approute";

function App() {
  return (
    <>
      <Approute />
    </>
  );
}

export default App;
