import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Enquiry } from "../pages/Enquiry";
import { HomePage } from "../pages/HomePage";
import {Test} from "../pages/Test"

interface ApprouteProps{}

export const Approute: React.FC<ApprouteProps> = () => {

return (
    <BrowserRouter>
    <Routes>
        <Route path="" element={<HomePage/>} />
        <Route path="Test" element={<Test/>} />
        <Route path="Enquiry" element={<Enquiry/>} />
    </Routes>
    </BrowserRouter>

);
};