import ChemLogo from "../images/Chem_Logo.png";

export const ProductList = [
    {
        Id:1,
        Image:ChemLogo,
        Name:"Chemical",
        Code:"BLS001001",
        Brand:"BIOGENE",
        Rate:"₹1750.00",
        Type:"Chemicals"
    },
    {
        Id:2,
        Image:ChemLogo,
        Name:"Chemical",
        Code:"BLS001001",
        Brand:"BIOGENE",
        Rate:"₹1800.00",
        Type:"Chemicals"
    },
    {
        Id:3,
        Image:ChemLogo,
        Name:"Chemical",
        Code:"BLS001003",
        Brand:"BIOGENE",
        Rate:"₹1850.00",
        Type:"Chemicals"
    },
    {
        Id:4,
        Image:ChemLogo,
        Name:"Chemical",
        Code:"BLS001004",
        Brand:"BIOGENE",
        Rate:"₹1900.00",
        Type:"Chemicals"
    },
    {
        Id:5,
        Image:ChemLogo,
        Name:"Chemical",
        Code:"BLS001005",
        Brand:"BIOGENE",
        Rate:"₹1950.00",
        Type:"Chemicals"
    },
    {
        Id:6,
        Image:ChemLogo,
        Name:"Instruments",
        Code:"BLS001006",
        Brand:"BIOGENE",
        Rate:"₹2000.00",
        Type:"Instruments"
    },
    {
        Id:7,
        Image:ChemLogo,
        Name:"Instruments",
        Code:"BLS001007",
        Brand:"BIOGENE",
        Rate:"₹2050.00",
        Type:"Instruments"
    },
    {
        Id:8,
        Image:ChemLogo,
        Name:"Instruments",
        Code:"BLS001008",
        Brand:"BIOGENE",
        Rate:"₹2100.00",
        Type:"Instruments"
    },
    {
        Id:9,
        Image:ChemLogo,
        Name:"Instruments",
        Code:"BLS001009",
        Brand:"BIOGENE",
        Rate:"₹2150.00",
        Type:"Instruments"
    },
    {
        Id:10,
        Image:ChemLogo,
        Name:"Instruments",
        Code:"BLS001010",
        Brand:"BIOGENE",
        Rate:"₹2200.00",
        Type:"Instruments"
    },
]

export const BrandList = [
    {
        Id:"1",
        Brand:"Biogene",
    },
    {
        Id:"2",
        Brand:"Realgene",
    },
    {
        Id:"3",
        Brand:"Takara",
    },
    {
        Id:"4",
        Brand:"Cell Signaling",
    },
    {
        Id:"5",
        Brand:"Qiagen",
    },
    {
        Id:"6",
        Brand:"Bio Cell",
    },
    {
        Id:"7",
        Brand:"Bio Legend",
    },
    {
        Id:"8",
        Brand:"Bio Labs",
    },
    {
        Id:"9",
        Brand:"My Bio Source",
    },
    {
        Id:"10",
        Brand:"Cytiva",
    },
    {
        Id:"11",
        Brand:"Axygen",
    },
    {
        Id:"12",
        Brand:"Corning",
    },
    {
        Id:"13",
        Brand:"Falcon",
    },
    {
        Id:"14",
        Brand:"Genaxy",
    },
]

export const MenuItem =[
    {
        Id:"1",
        Menu:"Chemicals"
    },
    {
        Id:"2",
        Menu:"Instruments"
    },
    {
        Id:"3",
        Menu:"Equipments"
    },
    {
        Id:"4",
        Menu:"Lab Consumables"
    },
    {
        Id:"5",
        Menu:"Safety Products"
    },
    {
        Id:"6",
        Menu:"Clean Room Products"
    },
    {
        Id:"7",
        Menu:"PlasticWares"
    },
    {
        Id:"8",
        Menu:"GlassWares"
    },
]

export const SelectType =[
    {
        Id:"1",
        SelectList:"View All",
    },
    {
        Id:"2",
        SelectList:"Chemicals",
    },
    {
        Id:"3",
        SelectList:"Instruments",
    },
]