import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./styles/HeadBar.css";
import LogoImg from "../images/Logo.png";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { IoMdArrowDropdown } from "react-icons/io";
import { MenuItem } from "../pages/UserData";
<link
  rel="stylesheet"
  href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
/>;

interface HeadBarProps {}

export const HeadBar: React.FC<HeadBarProps> = () => {

  const [signpop, setsignpop] = useState(false);
  console.log("signpop", signpop);
  const signRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (e: any) => {
      if (!signRef.current?.contains(e.target)) {
        setsignpop(false);
      }
    };
    document.addEventListener("mousedown", handler);
  });

  const [menupop, setmenupop] = useState(false);
  console.log("menupop", menupop);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (e: any) => {
      if (!menuRef.current?.contains(e.target)) {
        setmenupop(false);
      }
    };
    document.addEventListener("mousedown", handler);
  });

  const ChangeColor = (e: any) => {
    e.target.style.color = "crimson";
    e.target.style.font = "26";
    console.log("ChangeColor", HeadBar);
  };

  const ChangeColor2 = (e: any) => {
    e.target.style.color = "gray";
  };

  return (
    <>
      <div className="headerbar">
        <h2>Biogene Life Science</h2>
      </div>
      <div className="headerbar1">
        <div className="headerleft">
          <Link to={"/"}>
          <img className="biogenelogo" src={LogoImg} alt="Logo" />
          </Link>
        </div>
        <div className="headercenter"></div>
        <div className="headerright">
          <div className="signdiv">
            <p
              className="signdivp"
              onMouseOver={ChangeColor}
              onMouseOut={ChangeColor2}
              onClick={() => setsignpop(!signpop)}
            >
              SignIn
              <IoMdArrowDropdown />
            </p>
            {signpop && (
              <div className="signpop" ref={signRef}>
                <div
                  onMouseOver={ChangeColor}
                  onMouseOut={ChangeColor2}
                  className="signpop1"
                >
                  Login
                </div>
                <div
                  onMouseOver={ChangeColor}
                  onMouseOut={ChangeColor2}
                  className="signpop1"
                >
                  Logout
                </div>
                <div
                  onMouseOver={ChangeColor}
                  onMouseOut={ChangeColor2}
                  className="signpop1"
                >
                  Register
                </div>
              </div>
            )}
          </div>
          <div
            className="cartdiv"
            onMouseOver={ChangeColor}
            onMouseOut={ChangeColor2}
          >
            <AiOutlineShoppingCart size="25px" cursor="pointer" />
          </div>
        </div>
      </div>
      <div className="headerbar2">
        <div className="menu-div">
          <h3 onClick={() => setmenupop(!menupop)}>Menu</h3>
          {menupop && (
            <div className="menu-bar" ref={menuRef}>
              {MenuItem.map((item) => (
                <ul className="menu-ul">
                  <li className="menu-li">{item.Menu}</li>
                </ul>
              ))}
            </div>
           )}
        </div>
        <div className="headsearch">
          <form className="search-form">
            <input className="hsearch1" type="search" placeholder="Search..." />
            <span className="hsearchicon"></span>
          </form>
        </div>
        <Link to={"/Enquiry"}>
        <div className="menu-div">
          <h3>Enquiry</h3>
        </div>
        </Link>
      </div>
    </>
  );
};

export default HeadBar;
