import React, {useRef} from 'react';
import HeadBar from '../components/HeadBar';
import { InputText } from '../components/InputText';
import emailjs from '@emailjs/browser';
import "../styles/style.css"

// npm i @emailjs/browser

export const Enquiry: React.FC<any> =() => {
    const form = useRef(null);

    const sendEmail = (e:any) => {
        e.preventDefault();
    
        emailjs.sendForm('service_m01s535', 'template_kmrv50d', e.target, 'Jeq4gbZh74zrDETBh')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset()
      };

return(
<>
<HeadBar/>
<div className='enquiry-bdy'>
    <div className='enquiry-title'>
        <h2 className='enquiry-h2'>Enquiry Form</h2>
    </div>
<div className="enquiry-card">
<form ref={form} onSubmit={sendEmail}>
    <InputText type='text' name='user-name' placeholder='Name'/>
    <InputText type='text' name='user-company' placeholder='Company / Hospital / Institute / Others' />
    <InputText type='text' name='user-address' placeholder='Address' addclass='addres'/>
    <InputText type='text' name='user-enquiry' placeholder='Enquiry' addclass='descrp'/>
    <InputText type='text' name='user-mobile' placeholder='Mobile Number'/>
    <InputText type='text' name='user-email' placeholder='E-Mail Id'/>
    <InputText type='submit' value='Send' addclass='send-btn' />
</form>
</div>
</div>
</>
);
};

export default Enquiry;