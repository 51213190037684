import React from "react";
import { BrandList } from "../pages/UserData";
import "./styles/FilterData.css";

interface FilterDataProps {}

export const FilterData: React.FC<FilterDataProps> = () => {
  return (
    <>
      <div className="filter-body">
        <div className="filter-search">
          <input className="flsrch" type="search" placeholder="Search.."/>
        </div>
        <div className="flr-br-line"></div>
        <div className="filter-brand">
          <h3>Brand</h3>
          {BrandList.map((item) => (
            <div className="flr-brand-lst">
              <form>
                <div className="fltr-grp">
                  <input type="checkbox" />
                  <label>{item.Brand}</label>
                </div>
              </form>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
