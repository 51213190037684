import React from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./styles/SlideImage.css";

export const SlideImage = () => {
  const images = [
    "/slider_1.jpg",
    "/slider_2.jpg",
    "/slider_3.png",
    "/slider_4.jpg",
  ];

  return (
    <Slide>
      <div className="each-slide-effect">
        <div style={{ backgroundImage: `url(${images[0]})` }}>
          {/* <span>Slide 1</span> */}
        </div>
      </div>
      <div className="each-slide-effect">
        <div style={{ backgroundImage: `url(${images[1]})` }}>
          {/* <span>Slide 2</span> */}
        </div>
      </div>
      <div className="each-slide-effect">
        <div style={{ backgroundImage: `url(${images[2]})` }}>
          {/* <span>Slide 3</span> */}
        </div>
      </div>
      <div className="each-slide-effect">
        <div style={{ backgroundImage: `url(${images[3]})` }}>
          {/* <span>Slide 4</span> */}
        </div>
      </div>
    </Slide>
  );
};

export default SlideImage;
