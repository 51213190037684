import React from "react";
import "./styles/InputText.css"

interface InputTextProps{
type: 'text' | 'passwrod' | 'submit'
id?:string;
name?:string;
placeholder?:string;
addclass?:string;
label?:string;
value?:string;
}

export const InputText: React.FC<InputTextProps> = ({type,id,name,placeholder,addclass,label,value}) =>{
return(
    <>
    <div className="inpt-grp">
        <label htmlFor={id}>{label}</label>
        <input className={`input1 ${addclass}`} type={type} id={id} name={name} placeholder={placeholder} value={value} />
    </div>
    </>
);
};